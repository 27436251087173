import React from 'react';
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack';
import { Button } from '@mui/material';

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const notiRef = React.createRef<NotiSnackbarProvider>();

  return (
    <NotiSnackbarProvider
      ref={notiRef}
      maxSnack={3}
      action={key => (
        <Button
          variant="text"
          color="inherit"
          onClick={() => notiRef.current?.closeSnackbar(key)}
        >
          Dismiss
        </Button>
      )}
    >
      {children}
    </NotiSnackbarProvider>
  );
}
