import {NextPageContext} from 'next';
import React, {useContext, useMemo, useState, useEffect} from 'react';
import {QueryClient, useQuery} from 'react-query';
import {queries} from '@/common/api/operations';
import {MeResponse, UserRole} from '@/common/api/types';
import {storageKeys} from '@/common/config';
import {ssrAccessToken} from '@/common/utils/ssr';
import {storage} from '@/common/utils/storage';
import {ApiClient, useApiContext} from './ApiContext';
import {useLogout} from '../hooks/useLogout';

interface UserProviderProps {
  children: React.ReactNode;
}

interface UserContextState {
  user: MeResponse | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  listingId: number | null;
  isLoading: boolean;
  logout: () => void;
  delegatedEmail: string;
  isDelegatedSubscription: boolean | null;
  setUserId: (id: string) => void;
  reloadUser: () => void;
}

const defaultState = {
  user: null,
  isLoggedIn: false,
  listingId: null,
  isAdmin: false,
  isLoading: false,
  logout: () =>
    console.warn(
      'no-op: calling default logout, please make sure you are consuming the context properly',
    ),
  delegatedEmail: '',
  isDelegatedSubscription: null,
  setUserId: (id: string) => {},
  reloadUser: () => {},
};

export const UserContext = React.createContext<UserContextState>(defaultState);

export function UserProvider({children}: UserProviderProps) {
  const [delegatedUserId, setDelegatedUserId]: any = useState('');
  const [delegatedEmail, setDelegatedEmail]: any = useState('');
  const [isDelegatedSubscription, setDelegatedSubscription]: any =
    useState(null);

  const {apiClient} = useApiContext();
  const accessToken = storage.getItem(storageKeys.accessToken);
  const usersQuery = useQuery(queries.auth.me, apiClient.users.me, {
    enabled: Boolean(accessToken),
  });

  const {data: user, isLoading, refetch: userReretch} = usersQuery;

  const {data} = useQuery(queries.auth.myListing, apiClient.users.myListing, {
    enabled: Boolean(accessToken),
  });

  const delegatedUserQuery: {
    data?: {
      email?: string;
      isSubscription?: boolean | null;
      countMissingEmailRightsholder?: number;
      countMissingProperty?: number;
    };
    refetch: () => void;
  } = useQuery(queries.users.delegatedUser, () =>
    apiClient.users.delegatedUser({
      userId: delegatedUserId,
    }),
  );

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const {
    email = '',
    isSubscription = null,
    countMissingEmailRightsholder,
    countMissingProperty,
  } = delegatedUserQuery?.data || {};

  useEffect(() => {
    if (delegatedUserId === '') {
      setDelegatedEmail('');
      setDelegatedSubscription(null);
    } else {
      delegatedUserQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegatedUserId]);

  useEffect(() => {
    if (delegatedUserId === '' || !email) {
      setDelegatedEmail('');
    } else {
      setDelegatedEmail(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, delegatedUserId]);

  useEffect(() => {
    if (delegatedUserId === '' || isSubscription === null) {
      setDelegatedSubscription(null);
    } else {
      setDelegatedSubscription(isSubscription);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscription, delegatedUserId]);

  const {logout} = useLogout();
  const listingId = data?.listingId ?? null;

  const state = useMemo(() => {
    return {
      user: user
        ? {
            ...user,
            delegatedId: delegatedUserId,
            countMissingProperty: delegatedUserId
              ? countMissingProperty
              : user.countMissingProperty,
            countMissingEmailRightsholder: delegatedUserId
              ? countMissingEmailRightsholder
              : user.countMissingEmailRightsholder,
          }
        : null,
      isLoggedIn: Boolean(user),
      listingId,
      isLoading,
      isAdmin: user?.roles?.includes(UserRole.admin) ?? false,
      logout,
      delegatedEmail: delegatedEmail,
      isDelegatedSubscription: isDelegatedSubscription,
      setUserId: (id: string) => {
        setDelegatedUserId(id);
      },
      reloadUser: () => {
        userReretch();
      },
    };
  }, [
    user,
    logout,
    listingId,
    isLoading,
    delegatedUserId,
    delegatedEmail,
    isDelegatedSubscription,
  ]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}

export async function ssrMe(
  queryClient: QueryClient,
  apiClient: ApiClient,
  ctx: NextPageContext,
) {
  const accessToken = ssrAccessToken(ctx);

  if (accessToken) {
    await queryClient.prefetchQuery(queries.auth.me, apiClient.users.me);
  }
}

export function useUserContext() {
  return useContext(UserContext);
}
