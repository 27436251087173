import { isServerSide } from '@/common/utils/common';
import {api} from '@/common/config';
import React, { createContext, useContext, useState, useEffect } from 'react';
export type Globals = {
  siteName: string;
  copyrightName: string;
  siteURL: string;
  contactEmail: string;
  siteTagline: string;
  siteTaglineShort: string;
  siteLogoURL: string;
  siteLogoSmallURL: string;
  siteFaviconURL: string;
  allowProductLinks: Boolean;
  allowAudioUploads: Boolean;
  aboutUs: string;
  cookiePolicy: string;
  privacyPolicy: string;
  contentPolicy: string;
};

// export type GlobalContent = {
//   globals: Globals;
//   setGlobals: (globals: Globals) => void;
// };

const initialGlobalState = {
  siteName: 'BookMage BETA',
  copyrightName: '',
  siteURL: '',
  contactEmail: '',
  siteTagline:
    '',
  siteTaglineShort: '',
  siteLogoURL: '',
  siteLogoSmallURL: '',
  siteFaviconURL: '',
  allowProductLinks: true,
  allowAudioUploads: true,
  aboutUs: '',
  cookiePolicy: '',
  privacyPolicy: '',
  contentPolicy: '',
};

export const GlobalContext = createContext<Globals>({...initialGlobalState});

export const GlobalContextProvider = ({children}: any) => {
  const [globals, setGlobals] = useState({...initialGlobalState});

  useEffect(() => {
    const isServer = isServerSide();
    const baseUrl = isServer ? api.localBaseUrl : '';
    const url = '/api/site-settings'
    const fetchGlobal = async () => {
      await fetch(`${baseUrl}${url}`)
        .then(response => response.json())
        .then(result => {
          const newSiteSettings = result.reduce((prev: any, current: any) => {
            if (current.settingValue === 'true' || current.settingValue === 'false') {
              return {...prev, [current.settingName]: current.settingValue === "true"}
            }
            return {...prev, [current.settingName]: current.settingValue}
          }, {})
          setGlobals({ ...newSiteSettings })
        })
        .catch(error => {
          console.log('An error occured', error)
          setGlobals({...initialGlobalState})
        });
    };

    fetchGlobal();
  }, []);

  return (
    // the Provider gives access to the context to its children
    <GlobalContext.Provider value={globals}>{children}</GlobalContext.Provider>
  );
};
export const useGlobalContext = () => useContext(GlobalContext);
