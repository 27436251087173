export const queries = {
  search: {
    autocomplete: 'search.autocomplete',
    menuItems: 'search.menuItems',
    hashtagsAndListings: 'search.hashtagsAndListings',
    subhashtags: 'search.subhashtags',
    popularHashtags: 'search.popularHashtags',
    popularListingsPagination: 'search.popularListingsPagination',
    badgeListings: 'search.badgeListings',
  },
  auth: {
    me: 'auth.me',
    myListing: 'auth.myListing',
  },
  users: {
    paginate: 'users.paginate',
    get: 'users.get',
    delegatedUser: 'users.delegatedUser',
  },
  siteSettings: {
    paginate: 'siteSettings.paginate',
    get: 'siteSettings.get',
    count: 'siteSettings.count',
  },
  listings: {
    paginate: 'listings.paginate',
    get: 'listings.get',
    getBadges: 'listings.getBadges',
    getHashtags: 'listings.getHashtags',
    getBadgeRequests: 'listings.getBadgeRequests',
    getTwitterName: 'listings.getTwitterName',
  },
  listmageProfile: {
    get: 'listmageProfile.get',
  },
  badges: {
    autocomplete: 'badges.autocomplete',
    paginate: 'badges.paginate',
    get: 'badges.get',
    requests: {
      paginate: 'badges.requests.paginate',
      get: 'badges.requests.get',
    },
  },
  hashtags: {
    autocomplete: 'hashtags.autocomplete',
    paginate: 'hashtags.paginate',
    get: 'hashtags.get',
    getSubHashtags: 'hashtags.getSubHashtags',
  },
  faqs: {
    get: 'faqs.get',
  },
  faq: {
    update: 'faq.update',
    create: 'faqs.create',
    delete: 'faqs.delete',
  },
  rightsholders: {
    get: 'rightsholders.get',
    pagination: 'rightsholders.pagination',
  },
  rightsholder: {
    get: 'rightsholder.get',
    update: 'rightsholder.update',
    create: 'rightsholder.create',
    delete: 'rightsholder.delete',
    upload: 'rightsholder.upload',
  },
  properties: {
    get: 'properties.get',
    pagination: 'properties.pagination',
    getImage: 'properties.getImage',
  },
  property: {
    get: 'property.get',
    update: 'property.update',
    create: 'property.create',
    delete: 'property.delete',
  },
  propertylinks: {
    get: 'propertylinks.get',
  },
  propertylink: {
    update: 'propertylinks.update',
    create: 'propertylinks.create',
    delete: 'propertylinks.delete',
  },
  rightsholderlinks: {
    get: 'rightsholderlinks.get',
  },
  rightsholderlink: {
    update: 'rightsholderlink.update',
    create: 'rightsholderlink.create',
    delete: 'rightsholderlink.delete',
  },
  paymenttypes: {
    get: 'paymenttypes.get',
  },
  paymenttype: {
    update: 'paymenttype.update',
    create: 'paymenttype.create',
    init: 'paymenttype.init',
    delete: 'paymenttype.delete',
  },
  expenses: {
    get: 'expenses.get',
  },
  expense: {
    update: 'expense.update',
    create: 'expense.create',
    delete: 'expense.delete',
  },
  advances: {
    get: 'advances.get',
  },
  advance: {
    update: 'advance.update',
    create: 'advance.create',
    delete: 'advance.delete',
  },
  isbn: {
    get: 'isbn.get',
  },
  covers: {
    upload: 'covers.upload',
  },
  companySettings: {
    get: 'companySettings.get',
  },
  companySetting: {
    update: 'companySetting.update',
    create: 'companySetting.create',
    delete: 'companySetting.delete',
  },
  logos: {
    upload: 'logos.upload',
  },
  clientsecret: {
    get: 'clientsecret.get',
  },
  subscriptions: {
    create: 'subscriptions.create',
    delete: 'subscriptions.delete',
    get: 'subscriptions.get',
    managePaymentType: 'subscriptions.managePaymentType',
  },
  balances: {
    get: 'balances.get',
  },
  balance: {
    update: 'balance.update',
    create: 'balance.create',
    delete: 'balance.delete',
  },
  payments: {
    get: 'payments.get',
  },
  payment: {
    update: 'payment.update',
    create: 'payment.create',
    delete: 'payment.delete',
  },
  taxes: {
    get: 'taxes.get',
  },
  tax: {
    update: 'tax.update',
    create: 'tax.create',
    delete: 'tax.delete',
  },
  currencycountrycodes: {
    get: 'currencycountrycodes.get',
    getExcludebykey: 'taxes.getExcludebykey',
  },
  companysettingsdelegation: {
    get: 'companysettingsdelegation.get',
    update: 'companysettingsdelegation.update',
    create: 'companysettingsdelegation.create',
    delete: 'companysettingsdelegation.delete',
  },
  knowledgebase: {
    get: 'knowledgebase.get',
    pagination: 'knowledgebase.pagination',
    update: 'knowledgebase.update',
    create: 'knowledgebase.create',
    delete: 'knowledgebase.delete',
  },
  knowledgebasecategories: {
    get: 'knowledgebasecategories.get',
    update: 'knowledgebasecategories.update',
    create: 'knowledgebasecategories.create',
    delete: 'knowledgebasecategories.delete',
  },
  uploadfiles: {
    create: 'uploadfiles.create',
  },
  marketingrates: {
    get: 'marketingrates.get',
  },
  marketingrate: {
    update: 'marketingrate.update',
    create: 'marketingrate.create',
    delete: 'marketingrate.delete',
  },
  manualsalesentries: {
    get: 'manualsalesentries.get',
    pagination: 'manualsalesentries.pagination',
  },
  manualsalesentry: {
    update: 'manualsalesentry.update',
    create: 'manualsalesentry.create',
    delete: 'manualsalesentry.delete',
  },
  producttypes: {
    get: 'producttypes.get',
    update: 'producttypes.update',
    create: 'producttypes.create',
    delete: 'producttypes.delete',
  },
  propertytotal: {
    get: 'propertytotal.get',
  },
  pendingtotal: {
    get: 'pendingtotal.get',
  },
  propertymonthyear: {
    get: 'propertymonthyear.get',
  },
  rightsholdertotal: {
    get: 'rightsholdertotal.get',
    sendPDFreportToEmail: 'rightsholdertotal.sendPDFreportToEmail',
    sendRightsholderPDFreportForAllEmails:
      'rightsholdertotal.sendRightsholderPDFreportForAllEmails',
  },
  rightsholdermonthyear: {
    get: 'rightsholdermonthyear.get',
  },
  retailertotal: {
    get: 'retailertotal.get',
  },
  retailermonthyear: {
    get: 'retailermonthyear.get',
  },
  channeltotal: {
    get: 'channeltotal.get',
  },
  channelmonthyear: {
    get: 'channelmonthyear.get',
  },
  overviewtotal: {
    get: 'overviewtotal.get',
  },
  latestdatebychannel: {
    get: 'latestdatebychannel.get',
  },
  topsalestotal: {
    pay: 'topsalestotal.pay',
    accrue: 'topsalestotal.accrue',
    checkduplicatepayments: 'topsalestotal.checkduplicatepayments',
  },
  linkshorteners: {
    get: 'linkshorteners.get',
  },
  linkshortener: {
    update: 'linkshortener.update',
    create: 'linkshortener.create',
    delete: 'linkshortener.delete',
    info: 'linkshortener.info',
  },
};

// TODO: add response type
