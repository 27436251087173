import React from 'react';
import {createApiClient} from '../api/client';
import {storageKeys} from '../config';
import {useLogout} from '../hooks/useLogout';
import {useRefreshFlow} from '../hooks/useRefreshFlow';
import {storage} from '../utils/storage';
import { ApiError } from 'next/dist/server/api-utils';

export type ApiClient = ReturnType<typeof createApiClient>;

interface ApiContextState {
  apiClient: ApiClient;
}

const defaultState = {
  apiClient: null as unknown as ApiClient,
};

const ApiContext = React.createContext<ApiContextState>(defaultState);

export function ApiProvider({children}: {children: React.ReactNode}) {
  const {refreshTokens} = useRefreshFlow();
  const {logout} = useLogout();

  const state = React.useMemo<ApiContextState>(() => {
    const getAccessToken = () => storage.getItem(storageKeys.accessToken);
    const onApiError = async (error: ApiError | Error) => {
      const apiError = error as ApiError;

      if (apiError.statusCode !== 401) {
        return false;
      }

      const success = await refreshTokens();

      if (!success) {
        logout();
      }

      return success;
    };
    const apiClient = createApiClient(getAccessToken, onApiError);

    return {apiClient};
  }, [refreshTokens, logout]);

  return <ApiContext.Provider value={state}>{children}</ApiContext.Provider>;
}

export function useApiContext() {
  return React.useContext(ApiContext);
}
