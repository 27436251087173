// Outer
import React from 'react';
import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import createCache from '@emotion/cache';
import { AppProps } from 'next/app';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Inner
import theme from '@/common/theme';
import Root from '@/common/components/Root';
import { UserProvider } from '@/common/contexts/UserContext';
import { SnackbarProvider } from '@/common/contexts/SnackbarProvider';
import { ApiProvider } from '@/common/contexts/ApiContext';
import { ApiError } from '@/common/api/types';
import { useGlobalContext, GlobalContextProvider } from "../common/contexts/GlobalContext";

// import {siteName} from '../config';
export const cache = createCache({ key: 'css' });

import '../common/styles/global.css'

function App({ Component, pageProps }: AppProps) {
  const { siteName } = useGlobalContext()
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 60,
            retry: (failureCount: number, error) => {
              if (failureCount > 5) {
                return false;
              }

              const apiError = error as ApiError;

              return apiError.statusCode !== 401;
            },
          },
        },
      }),
  );
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  return (
    <GlobalContextProvider>
      <Head>
        <title>{siteName}</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        {/* @ts-ignore */}
        <Hydrate state={pageProps.dehydratedState}>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <ApiProvider>
                  <UserProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Root>
                        <CssBaseline />
                        {/* @ts-ignore */}
                        <Component {...pageProps} />
                      </Root>
                    </LocalizationProvider>
                  </UserProvider>
                </ApiProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </CacheProvider>
        </Hydrate>
      </QueryClientProvider>
    </GlobalContextProvider>
  );
}

export default App;
