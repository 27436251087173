import * as Sentry from '@sentry/nextjs';
import {useEffect} from 'react';
import {useUserContext} from '../contexts/UserContext';

function useSentryUser() {
  const {user, isLoading} = useUserContext();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      Sentry.setUser(null);
      return;
    }

    Sentry.setUser({id: user.id, email: user.email ?? undefined});
  }, [isLoading, user]);
}

export default function Root({children}: {children: React.ReactNode}) {
  useSentryUser();

  return <>{children}</>;
}
