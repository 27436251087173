import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#059FF5',
    },
    secondary: {
      main: '#005aaa',
    },
    background: {
      default: '#373942',
      paper: '#24262D',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiPaper: {

      styleOverrides: {
        root: {
          variants: [
            {
              style: {
                background: '#24262D',
              },
            },
          ],
        },
      },

    }
  }
});

export default theme;
