import * as Sentry from '@sentry/nextjs';
import {useCallback, useMemo} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {queries} from '@/common/api/operations';
import {ApiError, LogoutResponse} from '@/common/api/types';
import {appRoutes, storageKeys} from '@/common/config';
import {storage} from '@/common/utils/storage';
import {useRouter} from 'next/router';
import {useSnackbar} from 'notistack';
import {createApiClient} from '../api/client';

// const PRIVATE_PATHS: string[] = [
// TODO: add all private paths
// appRoutes.somePrivatePage
// ];

function isPublicPage() {
  return {
    status: false,
    path: window.document.location.pathname
  };
  // const path = window.document.location.pathname;
  // return PRIVATE_PATHS.includes(path) === false;
}

export function useLogout() {
  const apiClient = useMemo(
    () =>
      createApiClient(() => {
        throw new Error(
          'this client does not require an access token because it is intended for logout flow only',
        );
      }),
    [],
  );
  const {push} = useRouter();
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();

  const onSuccess = useCallback(() => {
    storage.removeItem(storageKeys.accessToken);
    storage.removeItem(storageKeys.refreshToken);
    queryClient.getQueryCache().find(queries.auth.me)?.reset();
    const {status, path} = isPublicPage();

    if (!status) {
      if(path !== '/') {
        push(appRoutes.auth.login(path));
      } else {
        push(appRoutes.home.root);
      }
    }
  }, [queryClient, push]);

  const onError = useCallback(
    (error: ApiError | Error) => {
      enqueueSnackbar(error.message, {variant: 'error'});
      Sentry.captureException(error);
    },
    [enqueueSnackbar],
  );

  const {mutate} = useMutation<LogoutResponse, ApiError | Error>(
    apiClient.users.logout,
    {onSuccess, onError},
  );

  const logout = useCallback(() => {
    mutate();
  }, [mutate]);

  return {logout};
}
