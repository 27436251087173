interface StoreData {
  [key: string]: unknown;
}

const data: StoreData = {};
const store = {
  getItem(key: string): string | null {
    return (data[key] as string) ?? null;
  },
  setItem(key: string, value: unknown) {
    data[key] = value;
  },
  removeItem(key: string) {
    delete data[key];
  },
};

export const storage =
  typeof window !== 'undefined' && typeof window.localStorage !== 'undefined'
    ? window.localStorage
    : store;
