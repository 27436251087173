import * as Sentry from '@sentry/nextjs';

const isLocalhost = process.env.NEXT_PUBLIC_IS_LOCAL === 'true';

Sentry.init({
  enabled: !isLocalhost,
  environment: isLocalhost ? 'local' : process.env.NODE_ENV,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  beforeSend: event => {
    return isLocalhost ? null : event;
  },
});
