import React from 'react';
import {useMutation} from 'react-query';
import {createApiClient} from '../api/client';
import {storageKeys} from '../config';
import {storage} from '../utils/storage';

export function useRefreshFlow() {
  const apiClient = React.useMemo(
    () =>
      createApiClient(() => {
        throw new Error(
          'this client does not require an access token because it is intended for refresh token flow only',
        );
      }),
    [],
  );
  const refreshTokenMutation = useMutation(apiClient.users.refresh);

  const refreshTokens = React.useCallback(async () => {
    const refreshToken = storage.getItem(storageKeys.refreshToken);

    if (!refreshToken) {
      return false;
    }

    try {
      const response = await refreshTokenMutation.mutateAsync({
        refreshToken,
      });
      storage.setItem(storageKeys.accessToken, response.accessToken);
      storage.setItem(storageKeys.refreshToken, response.refreshToken);
      return true;
    } catch (e: any) {
      console.log({type: 'RefreshTokenFlow', message: e.message});

      return false;
    }
  }, [refreshTokenMutation]);

  return {
    refreshTokens,
  };
}
