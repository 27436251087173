import queryString from 'query-string';

const stringifyUrl = queryString.stringifyUrl;

export const appRoutes = {
  home: {
    search: (text?: string) => stringifyUrl({url: `/search`, query: {q: text}}),
    category: (categoryName: string) => `/category/${categoryName}`,
    badges: (badgeName: string) => `/badges/${badgeName}`,
    root: '/',
    login: '/login',
  },
  static: {
    cookiesPolicy: '/cookies-policy',
    privacyPolicy: '/privacy-policy',
    contentPolicy: '/content-policy',
    faq: '/faq',
    aboutUs: '/about-us',
    contactUs: '/contact-us',
    filesUpload: '/files-upload',
    knowledgeBase: (tab?: string) => stringifyUrl({url: `/knowledge-base`, query: {tab}}),
    knowledgeBaseSingle: (categoryId?: number) => stringifyUrl({url: `/knowledge-base/${categoryId}`}),
    listmageProfile: (listmageProfileId?: number | 'new') =>
      stringifyUrl({url: `/listmage-profile/${listmageProfileId ?? ''}`}),
  },
  auth: {
    accountVerified: '/account-verified',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    signup: '/signup',
    refreshFlow: (redirectTo = '') => {
      return stringifyUrl({url: `/refresh`, query: {redirectTo}})
    },
    login: (redirectTo = '') => {
      return stringifyUrl({url: `/login`, query: {redirectTo}})
    },
  },
  account: {
    listing: (listingId: number | 'new') => `/listing/${listingId}`,
  },
  admin: {
    listings: (listingId?: number | 'new', tab?: string) =>
      stringifyUrl({url: `/admin/listings/${listingId ?? ''}`, query: {tab}}),
    hashtags: (hashtagId?: number | 'new', tab?: string) =>
      stringifyUrl({url: `/admin/hashtags/${hashtagId ?? ''}`, query: {tab}}),
    badges: (badgeId?: number | 'new') =>
      stringifyUrl({url: `/admin/badges/${badgeId ?? ''}`}),
    badgeRequests: (badgeRequestId?: number) =>
      stringifyUrl({url: `/admin/badge-requests/${badgeRequestId ?? ''}`}),
    users: (userId?: string) =>
      stringifyUrl({url: `/admin/users/${userId ?? ''}`}),
    siteSettings: (siteSettingsId?: string) =>
      stringifyUrl({url: `/admin/site-settings/${siteSettingsId ?? ''}`}),
    faq: (faqId?: string) =>
      stringifyUrl({url: `/admin/faq/${faqId ?? ''}`}),
    rightsholders: '/rightsholders',
    properties: (tab?: string) =>
      stringifyUrl({url: `/properties`, query: {tab}}),
    expenses: '/expenses',
    advances: '/advances',
    companySettings: (tab?: string) =>
      stringifyUrl({url: `/companysettings`, query: {tab}}),
    subscriptions: '/subscriptions',
    subscriptionsSuccess: '/subscriptions-success',
    balances: '/balances',
    payments: '/payments',
    taxes: '/taxes',
    marketingrates: '/marketingrates',
    manualsalesentry: '/manualsalesentry',
    overviewDashboard: '/overviewDashboard',
    propertyDashboard: '/propertyDashboard',
    rightsholderDashboard: '/rightsholderDashboard',
    retailerDashboard: '/retailerDashboard',
    channelDashboard: '/channelDashboard',
    linkShortener: '/linkShortener',
  },
};

export const api = {
  localBaseUrl: 'http://localhost:3000',
};

export const cookieNames = {
  session: 'x-session',
};

export const storageKeys = {
  accessToken: 'x-accessToken',
  refreshToken: 'x-refreshToken',
};

export const defaultTablePageSize = 25;
export const tablePageSizes = [25, 50, 100, 10000] as const;

export type PAGE_SIZE = typeof tablePageSizes[number];

export const flags = {
  ENABLE_CLAIM_LISTING: true,
  ENABLE_3RD_PARTY_TWITTER_FOLLOW: false,
  ENABLE_BADGE_IMAGE: false,
  ENABLE_BADGE_ICON: true,
} as const;

export const resolveImageUrl = (imageId: number) =>
  `/api/images/${imageId}/download`;

export const resolveAudioUrl = (audioId: number) =>
  `/api/audios/${audioId}/download`;

export const resolveCoverUrl = (coverId: number) =>
  `/api/covers/${coverId}/download`;

export const resolveLogoUrl = (logoId: number) =>
  `/api/logos/${logoId}/download`;

export const resolveRightsholderImageUrl = (imageId: number) =>
  `/api/rightsholderimages/${imageId}/download`;

export const isLocalhost = process.env.NEXT_PUBLIC_IS_LOCAL === 'true';

export const rightsholdersSortBy = 'fullName';
