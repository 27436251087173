export type ClickOrEnter = React.KeyboardEvent | React.MouseEvent;

const keyCodes = ['Enter'];

export function isEnter(e: React.KeyboardEvent) {
  return keyCodes.includes(e.code);
}

export function isClickOrEnter(e: ClickOrEnter) {
  const code = (e as React.KeyboardEvent).code || '';

  if (keyCodes.includes(code)) {
    return true;
  }

  if ((e as React.MouseEvent).clientX) {
    return true;
  }

  return false;
}

export function isServerSide() {
  return typeof window === 'undefined';
}

export function removeUndefinedFields<T extends {}>(obj: T) {
  return Object.entries(obj)
    .filter(([key, value]) => typeof value !== 'undefined')
    .reduce(
      (map, [key, value]) => ({
        ...map,
        [key]: value,
      }),
      {},
    );
}

export function removeNullFields(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });

  return obj;
}

export function formatShortCount(count: number, posfix: string) {
  let str: string;

  if (count < 1000) {
    str = count.toString();
  } else if (count < 1000 * 1000) {
    str = `${Math.round(count / 1000)}K`;
  } else if (count < 1000 * 1000 * 1000) {
    str = `${Math.round(count / (1000 * 1000))}M`;
  } else if (count < 1000 * 1000 * 1000 * 1000) {
    str = `${Math.round(count / (1000 * 1000 * 1000))}B`;
  } else {
    str = '+999B';
  }

  return `${str}${posfix}`;
}

export function isShowLegendsSettings(isShow: boolean) {
  return isShow ? {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    y: -2,
  } : { enabled:false }
}

export const setSetLegends = (
  array: Array<any>,
  key: string,
) => {
  const nameList = array?.map((element: any) => element[key]);
  const nameUniqueList = [...new Set(nameList)];

  return nameUniqueList.length <= 20;
}
